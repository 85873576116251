import React from "react";
import "../App.css";
const Filtry = ({
  showFilters,
  toggleFilters,
  activeButtons,
  setActiveButtons,
  showHelp,
  setShowHelp,
  showGuide,
}) => {
  //ovládá jaké filtry jsou aktivní - defultně je aktivní vše
  //logika toho že když je aktivní vše, tak se všechny ostatní vypnou
  const handleButtonClick = (buttonName) => {
    setActiveButtons((prevActiveButtons) => {
      const newActiveButtons = new Set(prevActiveButtons);

      if (buttonName === "VŠE") {
        if (newActiveButtons.has("VŠE")) {
          newActiveButtons.clear();
        } else {
          newActiveButtons.clear();
          newActiveButtons.add("VŠE");
        }
      } else {
        if (newActiveButtons.has(buttonName)) {
          newActiveButtons.delete(buttonName);
        } else {
          newActiveButtons.add(buttonName);
        }
        // Odstraníme "VŠE" pouze pokud není vybráno žádné jiné tlačítko
        if (newActiveButtons.has("VŠE") && newActiveButtons.size > 1) {
          newActiveButtons.delete("VŠE");
        }
      }

      return newActiveButtons;
    });
  };

  return (
    <div className="filtry">
      {!showGuide && (
        <img
          src={
            showHelp
              ? "./files/help-icon-active.svg"
              : "./files/help-icon-inactive.svg"
          }
          alt="Help"
          className="help-icon"
          onClick={() => setShowHelp((prevShowHelp) => !prevShowHelp)}
        />
      )}
      <div className="filtry-div">
        {showFilters ? (
          <div className="filtry-text" onClick={toggleFilters}>
            <img src="./files/chevron-up.svg" alt="chevron-up" />
            <h3>OTEVŘÍT FILTRY</h3>
          </div>
        ) : (
          <div className="filtry-buttons">
            <div className="filtry-text" onClick={toggleFilters}>
              <img src="./files/chevron-down.svg" alt="chevron-down" />
              <h3>ZAVŘÍT FILTRY</h3>
            </div>
            <div className="filtry-buttons-btn">
              <button
                className={activeButtons.has("VŠE") ? "active" : ""}
                onClick={() => handleButtonClick("VŠE")}
              >
                VŠE (1 379)
              </button>
              <button
                className={activeButtons.has("ORGANIZACE") ? "active" : ""}
                onClick={() => handleButtonClick("ORGANIZACE")}
              >
                ORGANIZACE (1 141)
              </button>
              <button
                className={
                  activeButtons.has("PŘÍSPĚVKOVÉ ORGANIZACE") ? "active" : ""
                }
                onClick={() => handleButtonClick("PŘÍSPĚVKOVÉ ORGANIZACE")}
              >
                PŘÍSPĚVKOVÉ ORGANIZACE (133)
              </button>
              <button
                className={activeButtons.has("LORA BRÁNY") ? "active" : ""}
                onClick={() => handleButtonClick("LORA BRÁNY")}
              >
                LORA BRÁNY (9)
              </button>
              <button
                className={
                  activeButtons.has("PROTIPOVODŇOVÉ SENZORY") ? "active" : ""
                }
                onClick={() => handleButtonClick("PROTIPOVODŇOVÉ SENZORY")}
              >
                PROTIPOVODŇOVÉ SENZORY (75)
              </button>
              <button
                className={activeButtons.has("ZZS ÚK") ? "active" : ""}
                onClick={() => handleButtonClick("ZZS ÚK")}
              >
                ZZS ÚK (21)
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Filtry;
