import React, { useRef } from "react";
import "../App.css";

const IconBox = (props) => {
  return (
    <div className="icon-box">
      <img src={props.icon} alt="logo" />
      <p>
        {props.text} a jejich umístění. Kliknutím na marker zobrazíte informace
        o konkrétní organizaci.
      </p>
    </div>
  );
};

const IconBox2 = (props) => {
  return (
    <div className="icon-box2">
      <div className="icon-box-icons">
        <img src={props.icon} alt="logo" />
        {props.icon2 && <img src={props.icon2} alt="logo" />}
      </div>
      <div className="icon-box-text">
        <h3>{props.title}</h3>
        <p>{props.text}</p>
      </div>
    </div>
  );
};

function Guide({ onClose, setShowGuide }) {
  // Zavře aktuální dialog či panel
  onClose();
  
  // Zobrazí průvodce po malém zpoždění
  setTimeout(() => {
    setShowGuide(true);
  }, 1);
}


const Help = ({ onClose, setShowGuide }) => {
  const downloadRef = useRef(null);
  return (
    <>
      <div className="help-container">
        <button className="help-closebtn" onClick={onClose}>
          <img src="./files/cross-icon.svg" alt="close" />
        </button>
        <p>
          LoRaMAP je interaktivní aplikace, která vám umožní sledovat a
          objevovat síť čidel a bran systému LoRaWAN v Ústeckém kraji. Pro
          uživatele je k dispozici mapa se všemi kategoriemi objektů v Ústeckém
          kraji, které disponují čidli sítě LoRaWAN. Aplikace umožňuje rozšíření
          mapového pokrytí přidáním vlastních markerů na místech, která signál z
          aktuálních čidel nepokrývá.
        </p>
        <h2>MAPA</h2>
        <p>
          Na mapě se orientujete klasickým zakliknutím a potažením mapy pomocí
          kurzoru. K přiblížení, či oddálení mapy použijte tlačítka “+” a “–” v
          levé horní části mapy. Na mapě jsou zaneseny všechny organizace
          Ústeckého kraje, rozdělené do kategorií dle typu. Každá kategorie má
          vlastní marker (ikonu), kterou se v mapě prezentuje (viz. část
          legenda). Kliknutím na marker se otevře okno s podrobnými informacemi
          o daném místě (název, adresa, typ a možnost navigace do daného místa).
          Organizace lze na mapě zobrazovat podle kategorií, které můžete
          navolit v panelu “Otevřít filtry” ve spodní části mapy. Je možné
          zobrazit více kategorií najednou. Podrobnější filtraci přímo v daných
          kategoriích lze poté vybrat přes tlačítko “Filtrace na mapě” (viz.
          část legenda), kde je možné ovládat zobrazení či skrytí dosahů LoRa
          čidel.
        </p>
        <h2>LEGENDA</h2>
        <IconBox
          icon="/files/organizace-location-point.svg"
          text={<b>Krajské organizace</b>}
        />
        <IconBox
          icon="/files/prispevkovky-location-point.svg"
          text={<b>Příspěvkové organizace</b>}
        />
        <IconBox
          icon="/files/lora-location-point.svg"
          text={<b>Lora Brány datového centra</b>}
        />
        <IconBox
          icon="/files/povodne-cidla-location-point.svg"
          text={<b>Protipovodňové senzory</b>}
        />
        <IconBox
          icon="/files/zzs-location-point.svg"
          text={<b>Výjezdové základny ZZS</b>}
        />
        <IconBox2
          icon="/files/add-marker-inactive.svg"
          icon2="/files/add-marker-active.svg"
          title="Přidat vlastní marker"
          text="Pro přidání vlastního markeru do mapy zaklikněte a aktivujte tlačítko. 
          Poté klikejte na libovolná místa na mapě. Po zakliknutí se vždy objeví 
          nejbližší organizace s čidli, která dané místo spravuje, a informace o 
          této organizaci. Data o přidaných markerech se mezitím uloží pod sekci 
          “Soubory”. Pro ukončení fuknce je nutné tlačítko opět zakliknout a deativovat."
        />
        <IconBox2
          icon="/files/files-inactive.svg"
          title="Filtrace na mapě"
          text="Kliknutím na tlačítko filtru se zobrazí okno s 
          možnostmi filtru týkající se  vybrané kategorie na mapě 
          (konkretní okres v ÚK, dosahy, vlastní markery apod.)."
        />
        <IconBox2
          icon="/files/location-filter-inactive.svg"
          title="Soubory"
          text="Rozkliknutím tlačítka soubory otevřete okno 
          s možností importu dat do mapy a v případě vložení 
          vlastních markerů se zobrazí informace o datech těchto 
          markerů a možnost jejich exportu do formátu .cvs."
        />
        <h2>DATA</h2>
        <p>
          Do mapy lze nahrát vlastní set dat přes funkci “Soubory” kde je
          nabídka možnosti importu. Soubor musí být ve formátu.csv a obsahovat
          následující data přehledně zapsaná v dokumentu:
        </p>
        <ol>
          <li>Název organizace</li>
          <li>Typ organizace (kategorie)</li>
          <li>ID organizace</li>
          <li>Adresa organizace</li>
          <li>GPS souřadnice</li>
          <li>Okres pod který organizace spadá</li>
        </ol>
        <p>
          Vzorový soubore ke stažení{" "}
          <a
            href="./files/loramap_zapis-dat-pro-import_VZOR.xlsx"
            download
            ref={downloadRef}
          >
            zde
          </a>
          .
          <br />
          <br />V případě vložení vlastních markerů do mapy, se informace o
          markerech promítnou také v sekci “Soubory” s možností exportu daných
          dat.
          <br />
          <br />
          <button onClick={() => Guide({ onClose, setShowGuide })}> Spustit průvodce </button>
        </p>
      </div>
    </>
  );
};

export default Help;
