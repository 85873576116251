import React, { useState, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import { BounceLoader } from "react-spinners";
import L from "leaflet";
import { MarkerMuster } from "react-leaflet-muster"; //odkomentovat pro použití marker clusteru
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Circle,
  useMapEvents,
  GeoJSON,
} from "react-leaflet";

const Map = ({
  setSavedLocations,
  locations,
  savedLocations,
  activeButtons,
  showRange,
  showSavedRange,
  penActive,
  checkedDistricts,
  importLocations,
  showImportMar,
  hlasiceData,
  loading,
}) => {
  const [geojsonData, setGeojsonData] = useState(null); // proměná pro uložení dat z geojson souboru (ustecký kraj)

  // načtení dat z geojson souboru
  useEffect(() => {
    fetch("/usteckykraj.json")
      .then((response) => response.json())
      .then((data) => {
        setGeojsonData(data);
      });
  }, []);

  // pokud se načítají data z geojson souboru, zobrazí se loading
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 3000);

    // Ujistěte se, že se timer vyčistí, pokud se komponenta odstraní
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <>
        <div className="loading">
          <BounceLoader color="#0f80d7" />
          {showButton && (
            <a href="https://cors-anywhere.herokuapp.com/corsdemo">
              <button>Nenačítá se dev verze? KLIKNI</button>
            </a>
          )}
        </div>
      </>
    );
  }

  // ikony pro jednotlivé kategorie
  const organizace = L.icon({
    iconUrl: "/files/organizace-location-point.svg",
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
  });

  const prispevkoveOrganizace = L.icon({
    iconUrl: "/files/prispevkovky-location-point.svg",
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
  });

  const loraBrany = L.icon({
    iconUrl: "/files/lora-location-point.svg",
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
  });

  const protipovodnovySenzory = L.icon({
    iconUrl: "/files/povodne-cidla-location-point.svg",
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
  });

  const zzsUk = L.icon({
    iconUrl: "/files/zzs-location-point.svg",
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
  });

  const ImportIcon = L.icon({
    iconUrl: "/files/vlastni-location-point.svg",
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
  });

  // barvy pro jednotlivé kategorie
  const categoryColors = {
    ORGANIZACE: "#fc9f32",
    "PŘÍSPĚVKOVÉ ORGANIZACE": "#fcc532",
    "LORA BRÁNY": "#113067",
    "PROTIPOVODŇOVÉ SENZORY": "#1e74cc",
    "ZZS ÚK": "#a84c60",
  };

  // komponenta pro zpracování kliknutí na mapu - ukládání bodů do uložených bodů
  const ClickHandler = ({
    locations,
    penActive,
    savedLocations,
    setSavedLocations,
  }) => {
    const map = useMapEvents({
      click: (e) => {
        if (!penActive) return;

        let closestLocation = null;
        let closestDistance = Infinity;

        for (const location of locations) {
          const [locLng, locLat] = location.geometry.coordinates;
          const distance = map.distance(e.latlng, L.latLng(locLat, locLng));

          if (distance < closestDistance) {
            closestDistance = distance;
            closestLocation = location;
          }
        }

        if (closestLocation) {
          // Check if the location is already saved
          if (
            activeButtons.has(closestLocation.properties.Type) ||
            activeButtons.has("VŠE")
          ) {
            const isLocationSaved = savedLocations.some(
              (loc) =>
                loc.lat === closestLocation.geometry.coordinates[1] &&
                loc.lng === closestLocation.geometry.coordinates[0]
            );

            if (!isLocationSaved) {
              // If not saved, add it to the savedLocations array along with the category
              setSavedLocations((prevSavedLocations) => [
                ...prevSavedLocations,
                {
                  name: closestLocation.properties.OrganizationName,
                  lat: closestLocation.geometry.coordinates[1],
                  lng: closestLocation.geometry.coordinates[0],
                  category: closestLocation.properties.Type,
                  okres: closestLocation.properties.municipality,
                  adresa: closestLocation.properties.Address,
                },
              ]);
            } else {
              // If already saved, remove it from the savedLocations array
              setSavedLocations((prevSavedLocations) =>
                prevSavedLocations.filter(
                  (loc) =>
                    loc.lat !== closestLocation.geometry.coordinates[1] ||
                    loc.lng !== closestLocation.geometry.coordinates[0]
                )
              );
            }
          }
        }
      },
    });

    return null;
  };

  // styl pro ustecký kraj
  const geoJSONStyle = (feature) => {
    return {
      fillColor: "black", // e.g., "#FF0000" for red
      fillOpacity: 0.1, // Set the opacity of the background color (0-1)
      color: "black", // Outline color
      weight: 1.5, // Outline width
    };
  };

  return (
    <MapContainer
      center={[50.660011, 14.042724]}
      zoom={10}
      className="leaflet-container"
    >
      <ClickHandler
        locations={locations}
        penActive={penActive}
        savedLocations={savedLocations}
        setSavedLocations={setSavedLocations}
      />

      {geojsonData && <GeoJSON data={geojsonData} style={geoJSONStyle} />}

      <TileLayer
        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        noWrap={true}
        attribution='<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <MarkerMuster>
        {/*<MarkerMuster>*/}
        {locations.map((location) => {
          const category = location.properties.Type;

          const popupStyle = {
            //backgroundColor: categoryColors[category], // Uncomment this if you need backgroundColor. Ensure 'categoryColors' object is defined.
            border: "4px solid " + categoryColors[category],
            borderRadius: "10px",
            padding: "15px",
          };

          const DistrictChecked = location.properties.municipality;

          if (
            (activeButtons.has("VŠE") || activeButtons.has(category)) &&
            checkedDistricts.has(DistrictChecked)
          ) {
            //if (activeButtons.has("VŠE") || activeButtons.has(category)) {
            return (
              <React.Fragment key={location.properties.MarkerNo}>
                <Marker
                  key={location.properties.MarkerNo}
                  icon={(() => {
                    switch (category) {
                      case "ORGANIZACE":
                        return organizace;
                      case "PŘÍSPĚVKOVÉ ORGANIZACE":
                        return prispevkoveOrganizace;
                      case "LORA BRÁNY":
                        return loraBrany;
                      case "PROTIPOVODŇOVÉ SENZORY":
                        return protipovodnovySenzory;
                      case "ZZS ÚK":
                        return zzsUk;
                      default:
                        return prispevkoveOrganizace;
                    }
                  })()}
                  position={[
                    location.geometry.coordinates[1],
                    location.geometry.coordinates[0],
                  ]}
                >
                  <Popup>
                    <div style={popupStyle}>
                      <strong>Název: </strong>
                      {location.properties.OrganizationName}
                      <br />
                      <strong>Adresa: </strong>
                      {location.properties.Address}
                      <br />
                      <strong>Okres: </strong>
                      {location.properties.municipality}
                      <br />
                      <br />
                      <a
                        href={`https://www.google.com/maps/place/${location.geometry.coordinates[1]},${location.geometry.coordinates[0]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Navigovat
                      </a>
                    </div>
                  </Popup>
                </Marker>
              </React.Fragment>
            );
          }

          // If the marker's category doesn't match the selected category, don't render the marker
          return null;
        })}

        {hlasiceData.map((item, index) => {
          const popupStyle = {
            border: "4px solid #1e74cc",
            borderRadius: "10px",
            padding: "15px",
          };

          if (
            activeButtons.has("VŠE") ||
            activeButtons.has("PROTIPOVODŇOVÉ SENZORY")
          ) {
            //if (activeButtons.has("VŠE") || activeButtons.has(category)) {
            return (
              <React.Fragment key={index}>
                <Marker
                  key={index}
                  icon={protipovodnovySenzory}
                  position={[item.lat, item.lon]}
                >
                  <Popup>
                    <div style={popupStyle}>
                      <strong>
                        Čidlo {item.aktivni === 1 ? "je" : "není"} aktivní
                      </strong>
                      <br />
                      <strong>Označení: </strong>
                      {item.ORP}
                      <br />
                      <strong>Obec: </strong>
                      {item.Obec}
                      <br />
                      <strong>Tok: </strong>
                      {item.Tok}
                      <br />
                      <strong>Hladina: </strong>
                      {item.hladina} cm
                      <br />
                      <strong>Teplota: </strong>
                      {item.teplota} °C
                      <br />
                      <br />
                      <a
                        href={`https://portabo.cz/voda/stanice/${item.ORP}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Více informací o čidlu
                      </a>
                    </div>
                  </Popup>
                </Marker>
              </React.Fragment>
            );
          }

          // If the marker's category doesn't match the selected category, don't render the marker
          return null;
        })}

        {/*</MarkerMuster>*/}
      </MarkerMuster>

      {locations.map((location) => {
        const category = location.properties.Type;

        const DistrictChecked = location.properties.municipality;

        if (
          (activeButtons.has("VŠE") || activeButtons.has(category)) &&
          checkedDistricts.has(DistrictChecked)
        ) {
          //if (activeButtons.has("VŠE") || activeButtons.has(category)) {
          const isLocationSaved = savedLocations.some(
            (loc) =>
              loc.lat === location.geometry.coordinates[1] &&
              loc.lng === location.geometry.coordinates[0]
          );
          return (
            <React.Fragment key={location.properties.MarkerNo}>
              {(showRange && showSavedRange && !isLocationSaved) |
                (showRange && !showSavedRange) &&
                category !== "PROTIPOVODŇOVÉ SENZORY" && (
                  <>
                    <Circle
                      center={[
                        location.geometry.coordinates[1],
                        location.geometry.coordinates[0],
                      ]}
                      radius={10000} // Dosah v metrech (zde nastaven na 1000 metrů)
                      fillColor={categoryColors[category]}
                      fillOpacity={0.3} // Průhlednost výplně dosahu (0-1)
                      color={categoryColors[category]} // Barva okraje dosahu
                      weight={0}
                    />

                    <Circle
                      center={[
                        location.geometry.coordinates[1],
                        location.geometry.coordinates[0],
                      ]}
                      radius={5000} // Dosah v metrech (zde nastaven na 1000 metrů)
                      fillColor={categoryColors[category]} // Barva výplně dosahu
                      fillOpacity={0.3} // Průhlednost výplně dosahu (0-1)
                      color={categoryColors[category]} // Barva okraje dosahu
                      weight={0}
                    />

                    <Circle
                      center={[
                        location.geometry.coordinates[1],
                        location.geometry.coordinates[0],
                      ]}
                      radius={2500} // Dosah v metrech (zde nastaven na 1000 metrů)
                      fillColor={categoryColors[category]} // Barva výplně dosahu
                      fillOpacity={0.3} // Průhlednost výplně dosahu (0-1)
                      color={categoryColors[category]} // Barva okraje dosahu
                      weight={0}
                    />
                  </>
                )}
            </React.Fragment>
          );
        }

        // If the marker's category doesn't match the selected category, don't render the marker
        return null;
      })}

      {showSavedRange &&
        savedLocations.map((location) => {
          // Check if the saved location's category is active
          const isCategoryActive =
            activeButtons.has("VŠE") || activeButtons.has(location.category);

          if (isCategoryActive) {
            return (
              <>
                <Circle
                  center={[location.lat, location.lng]}
                  radius={10000} // Dosah v metrech (zde nastaven na 1000 metrů)
                  fillColor="red"
                  fillOpacity={0.3} // Průhlednost výplně dosahu (0-1)
                  color="red" // Barva okraje dosahu
                  weight={0}
                />
                <Circle
                  center={[location.lat, location.lng]}
                  radius={5000} // Dosah v metrech (zde nastaven na 1000 metrů)
                  fillColor="red"
                  fillOpacity={0.3} // Průhlednost výplně dosahu (0-1)
                  color="red" // Barva okraje dosahu
                  weight={0}
                />
                <Circle
                  center={[location.lat, location.lng]}
                  radius={2500} // Dosah v metrech (zde nastaven na 1000 metrů)
                  fillColor="red"
                  fillOpacity={0.3} // Průhlednost výplně dosahu (0-1)
                  color="red" // Barva okraje dosahu
                  weight={0}
                />
              </>
            );
          }

          return null;
        })}

      {showImportMar &&
        importLocations.map((location) => {
          // Check if the saved location's category is active

          const popupStyle = {
            //backgroundColor: categoryColors[category], // Uncomment this if you need backgroundColor. Ensure 'categoryColors' object is defined.
            border: "4px solid #333333",
            borderRadius: "10px",
            padding: "15px",
          };

          if (1) {
            return (
              <>
                <Marker
                  icon={ImportIcon}
                  position={[location.lat, location.lng]}
                >
                  <Popup>
                    <div style={popupStyle}>
                      <strong>Název: </strong>
                      {location.name}
                      <br />
                      <strong>Adresa: </strong>
                      {location.adresa}
                      <br />
                      <strong>Okres: </strong>
                      {location.okres}
                      <br />
                      <br />
                      <a
                        href={`https://www.google.com/maps/place/${location.lat},${location.lng}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Navigovat
                      </a>
                    </div>
                  </Popup>
                </Marker>

                <Circle
                  center={[location.lat, location.lng]}
                  radius={10000} // Dosah v metrech (zde nastaven na 1000 metrů)
                  fillColor="#333333"
                  fillOpacity={0.3} // Průhlednost výplně dosahu (0-1)
                  color="#333333" // Barva okraje dosahu
                  weight={0}
                />
                <Circle
                  center={[location.lat, location.lng]}
                  radius={5000} // Dosah v metrech (zde nastaven na 1000 metrů)
                  fillColor="#333333"
                  fillOpacity={0.3} // Průhlednost výplně dosahu (0-1)
                  color="#333333" // Barva okraje dosahu
                  weight={0}
                />
                <Circle
                  center={[location.lat, location.lng]}
                  radius={2500} // Dosah v metrech (zde nastaven na 1000 metrů)
                  fillColor="#333333"
                  fillOpacity={0.3} // Průhlednost výplně dosahu (0-1)
                  color="#333333" // Barva okraje dosahu
                  weight={0}
                />
              </>
            );
          }

          return null;
        })}
    </MapContainer>
  );
};

export default Map;
