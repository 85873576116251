import React from "react";
import "../App.css";

const Guide = ({
    setShowGuide,
    currentStep,
    setCurrentStep,
    penActive,
    toggleFilters,
}) => {


    
  const guideSteps = [
    {
      text: "OTEVŘE OKNO S LEGENDOU A POPISEM APLIKACE",
      className: "step1",
    },
    {
      text: "OTEVŘE FILTR S MOŽNOSTÍ VÝBĚRŮ JEDNÉ NEBO VÍCE KATEGORIÍ ZOBRAZENÝCH NA MAPĚ",
      className: "step2",
    },
    {
      text: "+ PŘIBLÍŽÍ místo na mapě \n – ODDÁLÍ místo na mapě \n \n Zoom mapy lze ovládat také pomocí scrollovacího kolečka na myši, či pohybem dvou prstů k sobě a od sebe na touchpadu",
      className: "step3",
    },
    {
      text: "MOŽNOST PŘIDAT MARKER NA MAPU \n \n Kliknutím na tlačítko jej zaktivujete a povolíte funkci přidávání markeru přímo do mapy. kliknutím na mapu se automaticky označí nejbližší zodpovědná organizace. Pro vypnutí funkce je nutné na tlačítko opět kliknout a deaktivovat ho.",
      className: "step4",
    },
    {
      text: "OTEVŘE OKNO S FILTRY DANÝCH KATEGORIÍ NA MAPĚ",
      className: "step5",
    },
    {
      text: "MOŽNOST EXPORTU ČI IMPORTU DAT A ZOBRAZENÍ INFORMACÍ O VLOŽENÝCH MARKERECH",
      className: "step6",
    },
  ];

  const handleNextStep = () => {
    setCurrentStep((prevStep) => {
      if (prevStep === guideSteps.length - 1) {
        CloseGuide();
        return 0;
      }
      return prevStep + 1;
    });
  };

  const handleBeforeStep = () => {
    setCurrentStep((prevStep) => {
      if (prevStep === 0) {
        CloseGuide();
        return 0;
      }
      return prevStep - 1;
    });
  };

  const CloseGuide = () => {
    setShowGuide(false);
    setCurrentStep(0);
  };


    return (
    <div className="Guide">
          {currentStep === 0 && (
            <>
              <img
                src="./files/help-icon-inactive.svg"
                alt="Help"
                className="help-icon"
                id="Guide0"
              />
            </>
          )}
          {currentStep === 1 && (
            <div className="filtry-div" id="Guide1">
              <div className="filtry-text" onClick={toggleFilters}>
                <img src="./files/chevron-up.svg" alt="chevron-up" />
                <h3>OTEVŘÍT FILTRY</h3>
              </div>
            </div>
          )}
          {currentStep === 2 && (
            <img id="Guide2" src="./files/map-control.png" alt="Guide" />
          )}
          {currentStep === 3 && (
            <div className="buttons">
              <button
                id="Guilde3"
                className={`btn-icon${penActive ? " btnactive" : ""}`}
              >
                <img
                  src={
                    penActive ? "./files/pen_white.svg" : "./files/pen_blue.svg"
                  }
                  alt={"pen"}
                />
              </button>
            </div>
          )}
          {currentStep === 4 && (
            <div className="buttons">
              <button
                className={`btn-icon${penActive ? " btnactive" : ""}`}
              ></button>
              <button className="btn-icon" id="Guilde3">
                <img src="./files/layer_blue.svg" alt="pen" />
              </button>
            </div>
          )}
          {currentStep === 5 && (
            <div className="buttons">
              <button className="btn-icon"></button>
              <button className="btn-icon"></button>
              <button className="btn-icon lastbtnicon" id="Guilde3">
                <img src="./files/save_blue.svg" alt="pen" />
              </button>
            </div>
          )}

          <h4 className="Guide-closetext" onClick={CloseGuide}>
            UKONČIT PRŮVODCE <img src="./files/cross-icon.svg" alt="close" />{" "}
          </h4>
          <div className={`guide-step ${guideSteps[currentStep].className}`}>
            <h4>{guideSteps[currentStep].text}</h4>
            <div>
              {currentStep === 0 ? (
                <></>
              ) : (
                <button onClick={handleBeforeStep}>Vrátit se zpět</button>
              )}

              {currentStep === guideSteps.length - 1 ? (
                <button onClick={CloseGuide}>Začít používat aplikaci</button>
              ) : (
                <button onClick={handleNextStep}>Pokračovat dál</button>
              )}
            </div>
          </div>
        </div>
    );

};

export default Guide;