import React from "react";
import "../App.css";

const ImportExport = ({
  handleExport,
  onClose,
  savedLocations,
  handleImport,
  importLocations,
}) => {
  //pokud je nějaké uložené místo nebo importované místo a klikne se na export, tak se exportuje
  const handleExportClick = () => {
    if (savedLocations.length > 0 || importLocations.length > 0) {
      handleExport();
    }
  };
  return (
    <div className="import-export-container">
      <button className="layers-closebtn" onClick={onClose}>
        <img src="./files/cross-icon.svg" alt="close" />
      </button>

      <div
        className={`import-export-buttons ${
          (savedLocations.length !== 0 || importLocations.length !== 0) ? "" : "export-inactive"
        }`}
        onClick={handleExportClick}
      >
        {(savedLocations.length !== 0 || importLocations.length !== 0)  ? (
          <img src="./files/download.svg" alt="export" />
        ) : (
          <img src="./files/download-gray.svg" alt="export" />
        )}

        <h3>Stáhnout data</h3>
      </div>
      <div className="import-export-buttons">
        <label htmlFor="fileInput">
      <img src="./files/upload.svg" alt="import" />

          Nahrát data
        </label>
        <input
          id="fileInput"
          type="file"
          onChange={handleImport}
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
};

export default ImportExport;
