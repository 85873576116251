import React from "react";
import "../App.css";

const LogaContainer = ({windowWidth}) => {
  return (
    <div className="loga-container">
      <div className="loga">
        <img src="./files/eu-logo.svg" alt="evropská unie" />
        {windowWidth > 600 && (
          <>
            <img src="./files/uk-logo.svg" alt="ustecký kraj" />
          </>
        )}
        <img src="./files/portabo-logo.svg" alt="portabo" />
        {windowWidth > 600 && (
          <>
            <img src="./files/dcuk-logo.svg" alt="dcuk" />
            <img src="./files/tcuk-logo.svg" alt="tcuk" />
          </>
        )}
      </div>
    </div>
  );
};

export default LogaContainer;
