import React from "react";
import "../App.css";
const Layers = ({
  onClose,
  setShowRange,
  savedLocations,
  showSavedRange,
  setShowSavedRange,
  activeButtons,
  handleCheckboxChange,
  checkedDistricts,
  removeLocation,
  showImportMar,
  setShowImportMar,
  importLocations,
  removeImportLocation,
  districts
}) => {

  const CheckImport = (event) => {
    setShowImportMar(event.target.checked);
};


  return (
    <div className="layers-container">
      <button className="layers-closebtn" onClick={onClose}>
        <img src="./files/cross-icon.svg" alt="close" />
      </button>
      {activeButtons.size === 1 &&
      activeButtons.has("PROTIPOVODŇOVÉ SENZORY") ? (
        <></>
      ) : (
        <label>
          <input
            type="checkbox"
            onChange={() => setShowRange((prevShowRange) => !prevShowRange)}
          />
          {activeButtons.size === 1 && activeButtons.has("LORA BRÁNY")
            ? "Dosah LoRa"
            : activeButtons.size === 1 && activeButtons.has("ZZS ÚK")
            ? "Dosah ZZS"
            : "Zobrazit dosah"}
        </label>
      )}

      <label>
        <input
          type="checkbox"
          checked={showImportMar}
          onChange={CheckImport}
        />
        Místa ze souboru
      </label>

      <label>
        <input
          type="checkbox"
          checked={showSavedRange}
          onChange={() =>
            setShowSavedRange((prevShowSavedRange) => !prevShowSavedRange)
          }
        />
        Vlastní markery
      </label>

      <br />

      {(activeButtons.has("ORGANIZACE") ||
        activeButtons.has("PŘÍSPĚVKOVÉ ORGANIZACE") ||
        activeButtons.has("VŠE")) && (
          <>
          {districts.map(district => (
            <label key={district}>
              <input
                type="checkbox"
                checked={checkedDistricts.has(district)}
                onChange={(e) => handleCheckboxChange(district)}
              />
              {district}
            </label>
          ))}
        </>
      )}

      <div className="saved-locations">
        {savedLocations.length > 0 ? <h2>Uložené lokace</h2> : ""}
        <div className="savedloacationsdiv">
          {savedLocations.map((location, index) => (
            <div key={index} className="location-item">
              <p>
                {location.name}
              </p>
              <button onClick={() => removeLocation(index)}>✖</button>
            </div>
          ))}
           {importLocations.map((location, index) => (
            <div key={index} className="location-item">
              <p>
                {location.name}
              </p>
              <button onClick={() => removeImportLocation(index)}>✖</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Layers;