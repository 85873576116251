import "./App.css";
import React from "react";
import { useState, useEffect, useCallback } from "react";
import Help from "./components/Help";
import Layers from "./components/Layers";
import ImportExport from "./components/ImportExport";
import Guide from "./components/Guide";
import Map from "./components/Map";
import Filtry from "./components/Filtry";
import LogaContainer from "./components/LogaContainer";
const XLSX = require("xlsx");

function App() {
  // zde jsou definovány všechny proměnné, které se budou používat v aplikaci
  const [showLayers, setShowLayers] = useState(false); //proměná která ovládá zda se zobrazí ovládání vrstev
  const [showFilters, setShowFilters] = useState(true); //proměná která ovládá zda se zobrazí tlačítka na filtrování (ORGANIZACE, ZZS ÚK, ...)
  const [activeButtons, setActiveButtons] = useState(new Set(["VŠE"])); //Ukládá aktivní filtry, defaultně je nastaveno VŠE
  const [showRange, setShowRange] = useState(false); //proměná která ovládá zda se zobrazuje dosah u markerů které to dovolují
  const [showSavedRange, setShowSavedRange] = useState(true); //proměná která ovládá zda se zobrazuje dosah uložených markerů
  const [penActive, setPenActive] = useState(false); //proměná která ovládá zda je možnost přidávat vlastní markery
  const [savedLocations, setSavedLocations] = useState([]); //proměná pro ukládání vlastních markerů
  const [importLocations, setImportLocations] = useState([]); //proměná pro ukládání importovaných markerů
  const [locations, setLocations] = useState([]); //proměná pro ukládání všech markerů
  const [showHelp, setShowHelp] = useState(false); //proměná pro zobrazení nápovědy
  const [showImport, setShowImport] = useState(false); //proměná pro zobrazení možnosti importu a exportu
  const [currentStep, setCurrentStep] = useState(0); //proměná pro zobrazení aktuálního kroku v průvodci aplikací
  const [showGuide, setShowGuide] = useState(false); //proměná pro zobrazení průvodce aplikací
  const [loading, setLoading] = useState(false); //loading který se načítá zatímco se načítají data z API
  const [showImportMar, setShowImportMar] = useState(true); // proměná která určuje zda se zobrazují importované markery
  const [windowWidth, setWindowWidth] = useState(
    //proměná pro ukládání šířky okna
    typeof window !== "undefined" ? window.innerWidth : 0 //pokud je window undefined, tak se nastaví 0
  );
  const [hlasiceData, setHlasiceData] = useState([]); //proměná pro ukládání protipovodnových senzorů z API

  //spustí funkci pro získani protipovodnových senzorů
  useEffect(() => {
    ProtipovodnoveSenzoryFetch();
  }, []);

  //funkce pro získání protipovodnových senzorů z API
  //!!!!!!!!!!!!!!!!!!!!!!!!!PROVIZORNĚ BĚŽÍ PŘES CORS ANYWHERE!!!!!!!!!!!!!!!!!!!!!!!!!
  const ProtipovodnoveSenzoryFetch = async () => {
    const response = await fetch(
      "https://cors-anywhere.herokuapp.com/https://portabo.cz/voda/graphql", //api url
      {
        method: "POST",
        headers: {
          "Accept-Encoding": "gzip, deflate, br",
          "Content-Type": "application/json",
          Accept: "application/json",
          Connection: "keep-alive",
          DNT: "1",
          Origin: "https://portabo.cz",
        },
        body: JSON.stringify({
          query:
            "{\n  listHlasice {\n    hlasice {\n      lat\n      lon\n      ORP\n      Nazev\n      Obec\n      Tok\n      aktivni\n      floodstagelevelone\n      floodstageleveltwo\n      hladina\n      teplota\n    }\n  }\n}\n", //určuje jaká data se mají z API získat
        }),
      }
    );
    //dokumentace tady: https://portabo.cz/voda/api

    const data = await response.json();
    setHlasiceData(data.data.listHlasice.hlasice); //uloží data do proměné hlasiceData
    setLoading(false); //vypne načítaní = stránka je načtená
  };

  // pole kde jsou uložene všechny okresku pro použití v checkboxech
  const districts = [
    "okres Chomutov",
    "okres Louny",
    "okres Děčín",
    "okres Litoměřice",
    "okres Most",
    "okres Teplice",
    "okres Ústí nad Labem",
  ];

  const [checkedDistricts, setCheckedDistricts] = useState(new Set(districts)); //proměná na uložení všech zaškrtnutých okresů v checkboxech (defaultně jsou zaškrtlé všechny)

  //guide se pustí pouze při první návštěvě
  //nebo se uloží do localstorage že uživatel už průvodce viděl
  useEffect(() => {
    const isFirstVisit = !localStorage.getItem("visited");
    if (isFirstVisit) {
      setShowGuide(true);
      localStorage.setItem("visited", "true");
    }
  }, []);

  //při změně velikosti okna se nastaví nová šířka okna
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //ovládá zda je vidět panel s filtry dole
  const toggleFilters = () => {
    setShowFilters((prevState) => !prevState);
  };

  //ovládá zde jde přidávat vlastní markery
  const togglePenActive = () => {
    setPenActive((prevPenActive) => !prevPenActive);
  };

  //smaže uloženou lokaci
  function removeLocation(index) {
    const newSavedLocations = [...savedLocations];
    newSavedLocations.splice(index, 1);
    setSavedLocations(newSavedLocations); // Předpokládám, že máte stav pro savedLocations a funkci setSavedLocations.
  }

  //smaže importovanou lokaci
  function removeImportLocation(index) {
    const newImportLocations = [...importLocations];
    newImportLocations.splice(index, 1);
    setImportLocations(newImportLocations); // Předpokládám, že máte stav pro savedLocations a funkci setSavedLocations.
  }

  //načte lokace z json souboru
  useEffect(() => {
    fetch("/prispevkovky_UL.json")
      //fetch("/locations.json")
      .then((response) => response.json())
      .then((data) => setLocations(data.features)) //uloží data do proměné locations
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const zobrazbuttonvrstvy = () => {
    setShowLayers(true);
  };

  const zobrazvrstvy = () => {
    setShowLayers(false);
  };

  const zobrazbuttonimport = () => {
    setShowImport(true);
  };

  const zobrazimport = () => {
    setShowImport(false);
  };

  // Vytváříme callback funkci pro obsluhu importování souborů
const handleImport = useCallback((event) => {
  // Načtení vstupního souboru z události
  const input = event.target;
  const reader = new FileReader();

  // Nastavení funkce, která se provede po načtení souboru
  reader.onload = (e) => {
    // Získání obsahu souboru
    const data = e.target.result;

    // Použití knihovny XLSX k načtení dat z Excelu
    const workbook = XLSX.read(data, { type: "binary" });

    // Načtení názvu prvního listu v Excelu
    const worksheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[worksheetName];

    // Převod dat z listu na JSON
    const json = XLSX.utils.sheet_to_json(worksheet, { raw: true });

    // Zpracování dat z JSONu a vytvoření nového pole s upravenými daty
    const newImportLocations = json.map((item) => {
      let categoryFull;

      // Převedení hodnot "Pravní forma" na plné názvy kategorií
      switch (item["Pravní forma"]) {
        case "PO":
          categoryFull = "PŘÍSPĚVKOVÉ ORGANIZACE";
          break;
        case "O":
          categoryFull = "ORGANIZACE";
          break;
        case "LORA":
          categoryFull = "LORA BRÁNY";
          break;
        case "PPS":
          categoryFull = "PROTIPOVODŇOVÉ SENZORY";
          break;
        case "ZZS":
          categoryFull = "ZZS ÚK";
          break;
        default:
          categoryFull = item["Pravní forma"];
      }

      // Vytváříme a vracíme nový objekt pro každou položku
      return {
        name: item["název organizace"],
        category: categoryFull,
        adresa: item["adresa organizace (ulice č.p., PSČ, Město)"],
        lat: item["GPS latitude"],
        lng: item["GPS longitude"],
        okres: item.okres,
      };
    });

    // Vyprázdníme stávající lokace a nastavíme nové
    setImportLocations([]);
    setImportLocations(newImportLocations);
  };

  // Čteme soubor v binárním formátu
  reader.readAsBinaryString(input.files[0]);
}, []);


 // Funkce pro export lokací do souboru Excelu.
const handleExport = useCallback(() => {
  // Kombinuje uložené a importované lokace do jednoho pole.
  const allLocations = [...savedLocations, ...importLocations];

  // Kontroluje unikátnost lokací na základě jména a adresy.
  const uniqueLocations = allLocations.filter((location, index, self) => {
    return (
      location.name &&
      index ===
        self.findIndex(
          (l) => l.name === location.name && l.adresa === location.adresa
        )
    );
  });

  // Přeformátování dat pro export, zejména převod dlouhých kategorií na zkratky.
  const formattedData = uniqueLocations.map((location) => {
    let categoryAbbreviation;
    // Určení zkratky kategorie na základě dlouhého názvu.
    if (location.category === "PŘÍSPĚVKOVÉ ORGANIZACE") {
      categoryAbbreviation = "PO";
    } else if (location.category === "ORGANIZACE") {
      categoryAbbreviation = "O";
    } else if (location.category === "LORA BRÁNY") {
      categoryAbbreviation = "LORA";
    } else if (location.category === "PROTIPOVODŇOVÉ SENZORY") {
      categoryAbbreviation = "PPS";
    } else if (location.category === "ZZS ÚK") {
      categoryAbbreviation = "ZZS";
    } else {
      categoryAbbreviation = location.category; // Pokud kategorie neodpovídá žádné z výše uvedených, ponechá se její původní hodnota.
    }

    // Vrací přeformátované pole objektů připravené pro export do Excelu.
    return {
      "Název organizace": location.name,
      "Pravní forma": categoryAbbreviation,
      "Adresa organizace (ulice č.p., PSČ, Město)": location.adresa,
      "GPS latitude": location.lat,
      "GPS longitude": location.lng,
      "Okres": location.okres,
    };
  });

  // Vytvoří novou pracovní knihu a list s hlavičkou.
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(formattedData, {
    header: [
      "Název organizace",
      "Pravní forma",
      "Adresa organizace (ulice č.p., PSČ, Město)",
      "GPS latitude",
      "GPS longitude",
      "Okres",
    ],
  });

  // Nastavení šířky sloupců, aby byl export souboru snadno čitelný.
  worksheet["!cols"] = [
    { wch: 45 },
    { wch: 15 },
    { wch: 50 },
    { wch: 15 },
    { wch: 15 },
    { wch: 25 },
  ];

  // Přidáme list s daty do pracovní knihy pod názvem "Data".
  XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

  // Uloží pracovní knihu jako soubor data.xlsx.
  XLSX.writeFile(workbook, "data.xlsx");
}, [savedLocations, importLocations]);  // Závisí na dvou stavech: uložených a importovaných lokacích.


  //ukládá jaké okresy jsou zaškrtnuté a maže je pokud se odškrtnou
  function handleCheckboxChange(district) {
    setCheckedDistricts((prevCheckedDistricts) => {
      const newCheckedDistricts = new Set(prevCheckedDistricts);

      if (newCheckedDistricts.has(district)) {
        newCheckedDistricts.delete(district);
      } else {
        newCheckedDistricts.add(district);
      }

      console.log(newCheckedDistricts);
      return newCheckedDistricts;
    });
  }
// renderuje aplikaci
  return (
    <div className="App">
      <Map
        locations={locations}
        savedLocations={savedLocations}
        setSavedLocations={setSavedLocations}
        activeButtons={activeButtons}
        penActive={penActive}
        checkedDistricts={checkedDistricts}
        showRange={showRange}
        showSavedRange={showSavedRange}
        importLocations={importLocations}
        showImportMar={showImportMar}
        hlasiceData={hlasiceData}
        loading={loading}
      />

      {showGuide && currentStep < 6 && (
        <Guide
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setShowGuide={setShowGuide}
          penActive={penActive}
          toggleFilters={toggleFilters}
        />
      )}

      <div className="buttons">
        <button
          className={`btn-icon${penActive ? " btnactive" : ""}`}
          onClick={togglePenActive}
        >
          <img
            src={penActive ? "./files/pen_white.svg" : "./files/pen_blue.svg"}
            alt={"pen"}
          />
        </button>
        {showLayers ? (
          <Layers
            onClose={zobrazvrstvy}
            showRange={showRange}
            setShowRange={setShowRange}
            savedLocations={savedLocations}
            showSavedRange={showSavedRange}
            setShowSavedRange={setShowSavedRange}
            activeButtons={activeButtons}
            handleCheckboxChange={handleCheckboxChange}
            setCheckedDistricts={setCheckedDistricts}
            checkedDistricts={checkedDistricts}
            removeLocation={removeLocation}
            showImportMar={showImportMar}
            setShowImportMar={setShowImportMar}
            importLocations={importLocations}
            removeImportLocation={removeImportLocation}
            districts={districts}
          />
        ) : (
          <button className="btn-icon" onClick={zobrazbuttonvrstvy}>
            <img src="./files/layer_blue.svg" alt="pen" />
          </button>
        )}

        {showImport ? (
          <ImportExport
            handleExport={handleExport}
            onClose={zobrazimport}
            savedLocations={savedLocations}
            handleImport={handleImport}
            importLocations={importLocations}
          />
        ) : (
          <button className="btn-icon lastbtnicon" onClick={zobrazbuttonimport}>
            <img src="./files/save_blue.svg" alt="pen" />
          </button>
        )}
      </div>

      {showHelp ? (
        <Help setShowGuide={setShowGuide} onClose={() => setShowHelp(false)} />
      ) : null}

      <Filtry
        showFilters={showFilters}
        toggleFilters={toggleFilters}
        activeButtons={activeButtons}
        setActiveButtons={setActiveButtons}
        showHelp={showHelp}
        setShowHelp={setShowHelp}
        showGuide={showGuide}
      />

      <LogaContainer windowWidth={windowWidth} />
    </div>
  );
}

export default App;
